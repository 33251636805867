/**
 * @generated SignedSource<<64e2301cfbb7a9462718e7d9378b04b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionCreatedModalContent_data$data = {
  readonly logo: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "CollectionCreatedModalContent_data";
};
export type CollectionCreatedModalContent_data$key = {
  readonly " $data"?: CollectionCreatedModalContent_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionCreatedModalContent_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionCreatedModalContent_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "89416994c7af881e2536fdb37fa9bb13";

export default node;
