import React, { ReactNode } from "react"
import { useIsLessThanLg, FlexColumn } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Form } from "@/design-system/Form"
import { ResponsiveText } from "@/features/primary-drops/components/ResponsiveText"
import { media } from "@/styles/styleUtils"

export const CollectionFormWrapper = ({
  children,
  title,
}: {
  children: ReactNode
  title: string
}) => {
  const isLarge = !useIsLessThanLg()
  return (
    <StyledForm>
      {isLarge && <Heading size="large">{title}</Heading>}
      <FlexColumn className="gap-8">{children}</FlexColumn>
    </StyledForm>
  )
}

export const headingBottomPaddingOnCollectionFormCss = css`
  padding-bottom: 48px;
`

const Heading = styled(ResponsiveText.Heading)`
  ${headingBottomPaddingOnCollectionFormCss}
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  .StyledForm--required-label {
    color: ${props => props.theme.colors.error};
  }

  .CollectionForm--input-flagged {
    border-color: ${props => props.theme.colors.error};
  }

  .CollectionForm--link-logo {
    margin-right: 12px;
    width: 24px;
  }

  .CollectionForm--seller-fee-label {
    display: block;
    margin-top: 16px;
  }

  .CollectionForm--pills {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    .CollectionForm--category-button {
      margin-right: 12px;
    }
  }

  .CollectionForm--Input--links-twitter {
    border-radius: 0px;
  }

  .CollectionForm--card-display-styles {
    display: flex;
    max-width: 768px;

    .CollectionForm--card-display-style {
      flex: 1 0;
      position: relative;
      text-align: center;

      ${media({
        md: css`
          margin-left: 28px;
          max-height: 208px;
        `,
      })}
      &:first-child {
        margin-left: 0;
      }

      &.CollectionForm--card-display-style-checked {
        .CollectionForm--card-display-style-card {
          &,
          &:hover {
            border: 1px solid ${props => props.theme.colors.primary};
            box-shadow: 0px 0px 0px 1px ${props => props.theme.colors.primary};
          }
        }
      }

      .CollectionForm--card-display-style-card {
        border-radius: ${props => props.theme.borderRadius.default};
        border: 1px solid
          ${props => props.theme.colors.components.border.level2};
        cursor: pointer;
        height: 100%;
        padding: 12px 12px 20px 12px;
        transition-duration: 0.1s;

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${props => props.theme.colors.primary};
          box-shadow: 0px 0px 0px 1px ${props => props.theme.colors.primary};
        }

        &::before,
        &::after {
          display: none;
        }

        .CollectionForm--card-display-style-title {
          font-size: 16px;
          margin-top: 8px;
        }

        .CollectionForm--card-display-style-description {
          font-size: 14px;
          font-weight: initial;
          line-height: 1.5;
          opacity: 0.6;
        }
      }

      .CollectionForm--card-display-style-checkmark {
        position: absolute;
        top: -12px;
        right: -12px;
        background: ${props => props.theme.colors.primary};
        color: white;
        padding: 3px;
        border-radius: 50%;
        border: 3px solid white;
        box-shadow: ${props =>
          props.theme.colors.components.elevation.level1.regular.shadow};
      }
    }
  }

  .CollectionForm--submit {
    align-items: center;
    margin-bottom: 16px;
    margin-top: 32px;

    .CollectionForm--submit-spacer {
      flex: 1;
    }

    .CollectionForm--submit-indicator {
      display: flex;
      margin: 0px 16px;

      .CollectionForm--submit-indicator-icon {
        color: ${props => props.theme.colors.success};
        margin-right: 8px;
      }
    }
  }

  .Input--prefix {
    align-items: center;
    background-color: transparent;
    color: ${props => props.theme.colors.text.primary};
    display: flex;
  }

  .Input--info {
    align-items: center;
    display: flex;
    padding: 4px 4px 6px 4px;

    .Input--info-text {
      font-size: 12px;
      margin-left: 2px;
    }
  }

  .Input--info--invalid {
    color: ${props => props.theme.colors.error};

    .Input--info-icon {
      font-size: 18px;
    }
  }

  .Input--info--valid {
    color: ${props => props.theme.colors.success};

    .Input--info-icon {
      font-size: 18px;
    }
  }
` as typeof Form

export const TipText = styled(ResponsiveText.Body).attrs({
  marginY: "8px",
  size: "medium",
  color: "secondary",
})`
  color: ${props => props.theme.colors.text.secondary};
`
