import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import { ResponsiveText } from "@/features/primary-drops/components/ResponsiveText"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackContractDeployDeployed } from "@/lib/analytics/events/primaryDropsSelfServe"
import { CollectionCreatedModalContent_data$key } from "@/lib/graphql/__generated__/CollectionCreatedModalContent_data.graphql"
import { getCollectionEditUrl } from "@/lib/helpers/collection"

export const CollectionCreatedModalContent = ({
  dataKey,
}: {
  dataKey: CollectionCreatedModalContent_data$key | null
}) => {
  const t = useTranslate("components")

  const collection = useFragment<CollectionCreatedModalContent_data$key>(
    graphql`
      fragment CollectionCreatedModalContent_data on CollectionType {
        logo
        ...collection_url
      }
    `,
    dataKey,
  )
  useMountEffect(trackContractDeployDeployed)

  if (!collection) {
    return null
  }

  return (
    <>
      <Modal.Header />
      <Modal.Body centerAligned>
        <CenterAligned>
          <Block
            alignSelf="center"
            backgroundColor="oil"
            className="rounded-3xl"
            data-testid="completed-transaction-modal-container"
            height="184px"
            marginBottom="24px"
            overflow="hidden"
            width="184px"
          >
            {collection.logo && (
              <Image
                alt="Collection logo"
                height={184}
                objectFit="cover"
                src={collection.logo}
                width={184}
              />
            )}
          </Block>
          <Text.Heading asChild className="text-center" size="large">
            <h4>
              {t(
                "contractDeploy.collectionCreated.title",
                "Your collection is now created",
              )}
            </h4>
          </Text.Heading>
          <ResponsiveText.Body
            asChild
            className="mt-2 text-center text-secondary"
            size="medium"
          >
            <p>
              {t(
                "contractDeploy.collectionCreated.body",
                "Click “Continue editing” to continue customizing your collection’s details or to set up your drop.",
              )}
            </p>
          </ResponsiveText.Body>
        </CenterAligned>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          href={getCollectionEditUrl(collection)}
          variant="primary"
        >
          {t(
            "contractDeploy.collectionCreated.continueEditing",
            "Continue editing",
          )}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
