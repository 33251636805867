import React, { useEffect, useState, useRef } from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { Transaction } from "@/components/blockchain/BlockchainActionList/useHandleBlockchainActions"
import { useTransaction } from "@/components/blockchain/useTransaction"
import { MediaInputValue } from "@/components/forms/MediaInput"
import { Modal } from "@/design-system/Modal"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { ContractDeployTransactionModal_data$key } from "@/lib/graphql/__generated__/ContractDeployTransactionModal_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { captureNoncriticalError } from "@/lib/sentry"
import { FailedTransaction } from "./components/FailedTransaction"
import { PendingTransaction } from "./components/PendingTransaction"

type ContractDeployTransactionModalProps = {
  transaction?: Transaction
  onTransactionConfirmed?: () => unknown
  onTransactionFailed?: () => unknown
  collectionLogo: MediaInputValue
  dataKey: ContractDeployTransactionModal_data$key | null
  isProxy: boolean
  onClose: () => unknown
}

// TODO (roy): consolidate this modal with similar transaction modals (ie TransactionModalContent)
export const ContractDeployTransactionModal = ({
  transaction,
  isProxy,
  onTransactionConfirmed,
  onTransactionFailed,
  onClose,
  collectionLogo,
  dataKey,
}: ContractDeployTransactionModalProps) => {
  const [didTransactionFail, _setDidTransactionFail] = useState(false)
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  const data = useFragment(
    graphql`
      fragment ContractDeployTransactionModal_data on ChainType {
        blockExplorer {
          name
        }
      }
    `,
    dataKey,
  )

  const setIsTransactionPending = () => {
    _setDidTransactionFail(false)
  }
  const setIsTransactionConfirmed = () => {
    onTransactionConfirmed?.()
  }
  const setDidTransactionFail = () => {
    onTransactionFailed?.()
    _setDidTransactionFail(true)
  }
  const { pollTransaction } = useTransaction()

  const functionRefsValues = {
    setIsTransactionPending,
    setIsTransactionConfirmed,
    setDidTransactionFail,
    pollTransaction,
  }
  const functionRefs = useRef(functionRefsValues)
  functionRefs.current = functionRefsValues

  const transactionHash = transaction?.transactionHash
  const chain = transaction?.chain
  useEffect(() => {
    const poll = async () => {
      if (transactionHash && chain) {
        functionRefs.current.setIsTransactionPending()
        try {
          const result = await functionRefs.current.pollTransaction({
            transactionHash,
            chain,
          })
          if (result?.status) {
            functionRefs.current.setIsTransactionConfirmed()
          } else if (result && !result.status) {
            functionRefs.current.setDidTransactionFail()
          }
        } catch (error) {
          functionRefs.current.setDidTransactionFail()
          captureNoncriticalError(error)
        }
      }
    }
    poll()
  }, [transactionHash, chain])
  const blockExprorerName = data?.blockExplorer.name ?? "Etherscan"

  if (isMintYourOwnEnabled) {
    return didTransactionFail ? (
      <>
        <Modal.Header />
        <Modal.Body centerAligned>
          <FlexColumn
            className="items-center"
            data-testid="contract-deploy-transaction-modal-container"
          >
            <FailedTransaction
              blockExplorerName={blockExprorerName}
              transaction={transaction}
            />
          </FlexColumn>
        </Modal.Body>
      </>
    ) : (
      <PendingTransaction
        blockExplorerName={blockExprorerName}
        collectionLogo={collectionLogo}
        isProxy={isProxy}
        transaction={transaction}
        onClose={onClose}
      />
    )
  }

  return (
    <>
      <Modal.Header />
      <Modal.Body centerAligned>
        <FlexColumn
          className="items-center"
          data-testid="contract-deploy-transaction-modal-container"
        >
          {didTransactionFail ? (
            <FailedTransaction
              blockExplorerName={blockExprorerName}
              transaction={transaction}
            />
          ) : (
            <PendingTransaction
              blockExplorerName={blockExprorerName}
              collectionLogo={collectionLogo}
              isProxy={isProxy}
              transaction={transaction}
              onClose={onClose}
            />
          )}
        </FlexColumn>
      </Modal.Body>
    </>
  )
}
