import { isAudioUrl, isImageUrl, isVideoUrl, isWebGlUrl } from "./urls"

export type MediaType = "audio" | "video" | "image" | "webgl" | "mux_video"

export const mediaTypeFromUrl = (url: string): MediaType | undefined => {
  return isVideoUrl(url)
    ? "video"
    : isAudioUrl(url)
    ? "audio"
    : isWebGlUrl(url)
    ? "webgl"
    : isImageUrl(url)
    ? "image"
    : undefined
}

export const mediaTypeFromFile = ({
  type,
  name,
}: File): MediaType | undefined => {
  if (type.startsWith("video/")) {
    return "video"
  } else if (type.startsWith("audio/")) {
    return "audio"
  } else if (type.startsWith("model/")) {
    return "webgl"
  } else if (type.startsWith("image/")) {
    return "image"
  } else {
    return mediaTypeFromUrl(name)
  }
}
