import React, { useEffect, useState } from "react"
import { Flex, Icon, Spinner, Text } from "@opensea/ui-kit"
import { getContractAddress } from "ethers/lib/utils"
import { first, find } from "lodash"
import { useInterval } from "react-use"
import { Transaction } from "@/components/blockchain/BlockchainActionList/useHandleBlockchainActions"
import { Link } from "@/components/common/Link"
import { MediaInputValue } from "@/components/forms/MediaInput"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { uploadCollectionImages } from "@/features/collections/components/CollectionCreateOrUpdatePage/hooks/useCollectionForm"
import { useStudioTracking } from "@/features/creator-studio/hooks/useStudioTracking"
import { useDeployContractStore } from "@/features/creator-studio/stores/deployContractStore"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import { trackContractDeployPending } from "@/lib/analytics/events/primaryDropsSelfServe"
import { PendingTransactionEditMutation } from "@/lib/graphql/__generated__/PendingTransactionEditMutation.graphql"
import { PendingTransactionQuery } from "@/lib/graphql/__generated__/PendingTransactionQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { CollectionCreatedModalContent } from "../../../CollectionCreatedModalContent"
import {
  DeployContractProgress,
  LOADING_CONTENT_STATE,
} from "../../../DeployContractProgress"

const REFETCH_INTERVAL = 3 * 1000 // every 3 seconds

// SeaDropTokenDeployed topic
const SEA_DROP_TOKEN_DEPLOYED_TOPICS = [
  // Proxy drop
  // https://goerli.etherscan.io/tx/0xfe6939a754f850a8a75b18dd072a2419a78effe9c23ca026f64407c65d2a87e6#eventlog
  "0xc90c61dd7a67259711a8a4c0b50bc6130257c5ba9f2539c5050264827f3819ea",
  // Proxy myo
  // https://polygonscan.com/tx/0xd0f36ef347ebd7b191be4ee8e0c4cbb0e7fa88ce46cd7bdb1ae38cd364735b28#eventlog
  "0xd7aca75208b9be5ffc04c6a01922020ffd62b55e68e502e317f5344960279af8",
]

export const PendingTransaction = ({
  transaction,
  collectionLogo,
  blockExplorerName,
  isProxy,
  onClose,
}: {
  blockExplorerName: string
  transaction?: Transaction
  isProxy: boolean
  collectionLogo: MediaInputValue
  onClose: () => void
}) => {
  const t = useTranslate("collectionEdit")
  const [contractAddress, setContractAddress] = useState<
    string | null | undefined
  >(null)
  useEffect(() => {
    const setAddressAsync = async () => {
      const evmResponse = transaction?.evmResponse
      if (!evmResponse) {
        return
      }

      let address: string | undefined
      if (isProxy) {
        // We find the log where the contract was deployed (that emits the event SeaDropTokenDeployed), and we get the address from there.
        const receipt = await evmResponse.wait()
        const log = find(receipt.logs, log =>
          find(log.topics, topic =>
            SEA_DROP_TOKEN_DEPLOYED_TOPICS.includes(topic),
          ),
        ) as undefined | { address: string }
        address = log?.address
      } else {
        address = getContractAddress(evmResponse)
      }

      address && setContractAddress(address)
    }
    setAddressAsync()
  }, [isProxy, transaction])
  const [data, refetch] = useNoSuspenseLazyLoadQuery<PendingTransactionQuery>(
    graphql`
      query PendingTransactionQuery(
        $contractAddress: AddressScalar
        $hasContractAddress: Boolean!
      ) {
        collections(assetContractAddress: $contractAddress, first: 1)
          @include(if: $hasContractAddress) {
          edges {
            node {
              relayId
              slug
              ...CollectionCreatedModalContent_data
            }
          }
        }
      }
    `,
    { contractAddress, hasContractAddress: !!contractAddress },
  )
  const { mutate } = useGraphQL()
  const collection = first(getNodes(data?.collections))
  const { onReplace } = useMultiStepFlowContext()
  const [uploadingLogo, setUploadingLogo] = useState(false)
  const mintYourOwnEnabled = useIsMintYourOwnEnabled()
  const trackContractDeployed = useStudioTracking("contract deployed")
  useEffect(() => {
    if (collection && !uploadingLogo) {
      ;(async () => {
        setUploadingLogo(true)
        // this is only uploading. we should just mutate directly, copy relevant part from uploadCollectionImages and editCollection
        const collectionUpdate = await uploadCollectionImages(
          mutate,
          collection.relayId,
          {
            collection: collection.relayId,
            logoImage: collectionLogo.file,
            bannerImage: null,
            featuredImage: null,
          },
          false,
        )
        const response = await mutate<PendingTransactionEditMutation>(
          graphql`
            mutation PendingTransactionEditMutation(
              $input: CollectionModifyMutationInput!
            ) {
              collections {
                modify(input: $input) {
                  logo
                  ...CollectionCreatedModalContent_data
                }
              }
            }
          `,
          {
            input: {
              collectionInput: {
                ...collectionUpdate,
              },
            },
          },
        )

        if (mintYourOwnEnabled) {
          trackContractDeployed()
          useDeployContractStore.getState().contractDeployed({
            slug: collection.slug,
            logoUrl: collectionLogo.url,
            blockExplorerName,
            blockExplorerUrl: transaction?.blockExplorerLink ?? "",
          })
          onClose()
        } else {
          onReplace(
            <CollectionCreatedModalContent
              dataKey={response.collections.modify}
            />,
          )
        }
      })()
    }
  }, [
    collection,
    uploadingLogo,
    mutate,
    collectionLogo,
    onReplace,
    blockExplorerName,
    mintYourOwnEnabled,
    onClose,
    transaction,
    trackContractDeployed,
  ])
  useInterval(
    () => refetch({ contractAddress, hasContractAddress: !!contractAddress }),
    REFETCH_INTERVAL,
  )

  useMountEffect(trackContractDeployPending)

  if (mintYourOwnEnabled) {
    return (
      <DeployContractProgress
        customRender={
          transaction?.blockExplorerLink && (
            <Link href={transaction.blockExplorerLink}>
              <Flex className="h-5 !place-items-center">
                <Text.Body className="!font-normal" size="small">
                  {t(
                    "contractDeploy.pending.explorerLink",
                    "View on {{explorerName}}",
                    { explorerName: blockExplorerName },
                  )}
                </Text.Body>
                <Icon className="ml-1" size={16} value="open_in_new" />
              </Flex>
            </Link>
          )
        }
        state={LOADING_CONTENT_STATE.PENDING_TRANSACTION}
      />
    )
  }

  return (
    <>
      <Spinner size="medium" />
      <Block marginY="24px">
        <Modal.Body.Title asChild className="text-center">
          <h4>
            {t(
              "contractDeploy.pending.title",
              "Your contract is being deployed",
            )}
          </h4>
        </Modal.Body.Title>
      </Block>
      <Block marginBottom="24px">
        <Text.Body asChild className="text-center" size="medium">
          <p>
            {t(
              "contractDeploy.pending.description",
              "Your new contract is being deployed. It may take some time for the transaction to be processed and the collection to be reflected on OpenSea.",
            )}
          </p>
        </Text.Body>
      </Block>
      {transaction?.blockExplorerLink && (
        <Block marginBottom="48px">
          <Link href={transaction.blockExplorerLink}>
            {t(
              "contractDeploy.pending.explorerLink",
              "View on {{explorerName}}",
              { explorerName: blockExplorerName },
            )}
          </Link>
        </Block>
      )}
    </>
  )
}
