/**
 * @generated SignedSource<<182632b3cf638b36f6f34ab409c7d144>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type RarityStrategyId = "OPENRARITY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCollectionForm_data$data = {
  readonly collection?: {
    readonly assetContracts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string | null;
          readonly owner: {
            readonly __typename: "AccountType";
          } | null;
        } | null;
      } | null>;
    };
    readonly bannerImageUrl: string | null;
    readonly canSetRoyaltyInfo: boolean;
    readonly category: {
      readonly slug: string;
    } | null;
    readonly createdDate: string;
    readonly creatorFees: ReadonlyArray<{
      readonly account: {
        readonly address: string;
      } | null;
      readonly basisPoints: number;
    }>;
    readonly defaultChain: {
      readonly identifier: ChainIdentifier;
    };
    readonly description: string | null;
    readonly discordUrl: string | null;
    readonly displayData: {
      readonly cardDisplayStyle: CardDisplayStyle | null;
    };
    readonly enabledRarities: ReadonlyArray<RarityStrategyId>;
    readonly externalUrl: string | null;
    readonly featuredImageUrl: string | null;
    readonly imageUrl: string | null;
    readonly instagramUsername: string | null;
    readonly isCreatorFeesEnforced: boolean;
    readonly isNsfw: boolean;
    readonly isTraitOffersEnabled: boolean;
    readonly mediumUsername: string | null;
    readonly name: string;
    readonly parents: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly imageUrl: string | null;
          readonly name: string;
          readonly slug: string;
        } | null;
      } | null>;
    };
    readonly paymentAssets: ReadonlyArray<{
      readonly relayId: string;
    }>;
    readonly relayId: string;
    readonly slug: string;
    readonly tags: ReadonlyArray<{
      readonly slug: string;
    }> | null;
    readonly telegramUrl: string | null;
    readonly twitterUsername: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"useCollectionFormCollectionEditInput_data">;
  } | null;
  readonly paymentAssets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly assetContract: {
            readonly chain: ChainIdentifier;
          };
          readonly imageUrl: string | null;
        };
        readonly isDefault: boolean;
        readonly relayId: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "useCollectionForm_data";
};
export type useCollectionForm_data$key = {
  readonly " $data"?: useCollectionForm_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCollectionForm_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DisplayDataType",
  "kind": "LinkedField",
  "name": "displayData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardDisplayStyle",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "collection"
    },
    {
      "kind": "RootArgument",
      "name": "isExistingCollection"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCollectionForm_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 750
        },
        {
          "kind": "Variable",
          "name": "validForCollection",
          "variableName": "collection"
        }
      ],
      "concreteType": "PaymentAssetTypeConnection",
      "kind": "LinkedField",
      "name": "paymentAssets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAssetTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetContractType",
                      "kind": "LinkedField",
                      "name": "assetContract",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "chain",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDefault",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isExistingCollection",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "collection",
              "variableName": "collection"
            }
          ],
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 2
                }
              ],
              "concreteType": "AssetContractTypeConnection",
              "kind": "LinkedField",
              "name": "assetContracts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetContractType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountType",
                          "kind": "LinkedField",
                          "name": "owner",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetContracts(first:2)"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CategoryType",
              "kind": "LinkedField",
              "name": "category",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TagType",
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "defaultChain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discordUrl",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "featuredImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instagramUsername",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bannerImageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mediumUsername",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTraitOffersEnabled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "paymentAssets",
              "plural": true,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "telegramUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "twitterUsername",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FeeType",
              "kind": "LinkedField",
              "name": "creatorFees",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountType",
                  "kind": "LinkedField",
                  "name": "account",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "basisPoints",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNsfw",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 30
                }
              ],
              "concreteType": "CollectionTypeConnection",
              "kind": "LinkedField",
              "name": "parents",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CollectionTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CollectionType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "parents(first:30)"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "enabledRarities",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCreatorFeesEnforced",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canSetRoyaltyInfo",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useCollectionFormCollectionEditInput_data",
              "selections": [
                (v1/*: any*/),
                (v5/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "257c048221f8fd9f662df1040fab2e7b";

export default node;
