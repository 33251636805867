import React from "react"
import { Text as BaseText } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { media } from "@/styles/styleUtils"

// TODO: Remove this css once we port ResponsiveText to DS repo
const displayVariablesCss = css`
  --text-extra-large: 60px;
  --text-large: 48px;
  --text-medium: 32px;
  --text-small: 24px;
  --text-lh-extra-large: 72px;
  --text-lh-large: 56px;
  --text-lh-medium: 36px;
  --text-lh-small: 32px;
`

export const headingVariablesCss = css`
  --text-large: 32px;
  --text-medium: 24px;
  --text-small: 20px;
  --text-tiny: 18px;
  --text-lh-large: 40px;
  --text-lh-medium: 32px;
  --text-lh-small: 28px;
  --text-lh-tiny: 26px;
`

export const bodyVariablesCss = css`
  --text-medium: 16px;
  --text-small: 14px;
  --text-tiny: 12px;
  --text-lh-medium: 24px;
  --text-lh-small: 20px;
  --text-lh-tiny: 18px;
`

type BodyParameters = Parameters<typeof BaseText.Body>[0]

const responsiveBodyVariablesCss = css`
  ${bodyVariablesCss};
  --text-xsmall: 12px;
  --text-lh-xsmall: 20px;
`

const BodySmall = styled(BaseText.Body)`
  ${responsiveBodyVariablesCss}
  ${media({
    xxl: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
    xs: css`
      font-size: var(--text-xsmall);
      line-height: var(--text-lh-xsmall);
    `,
  })}
`

const BodyMedium = styled(BaseText.Body)`
  ${responsiveBodyVariablesCss}
  ${media({
    lg: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    xs: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
  })}
`

const Body = (props: BodyParameters) => {
  switch (props.size) {
    case "small":
      return <BodySmall {...props} />
    case "medium":
      return <BodyMedium {...props} />
    default:
      return <BaseText.Body {...props} />
  }
}

type HeadingParameters = Parameters<typeof BaseText.Heading>[0]

const responsiveHeadingVariablesCss = css`
  ${headingVariablesCss};
  --text-xsmall: 18px;
  --text-lh-xsmall: 24px;
  --text-tiny: 16px;
  --text-lh-tiny: 24px;
`

const HeadingMedium = styled(BaseText.Heading)`
  ${responsiveHeadingVariablesCss}
  ${media({
    xxl: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    lg: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
    xs: css`
      font-size: var(--text-xsmall);
      line-height: var(--text-lh-xsmall);
    `,
  })}
`

// TODO(samhv | roy) let's refactor it
// Benji explained this should be a special case for Modals
// I think we should move away of this differences
const HeadingMediumOnModal = styled(BaseText.Heading)`
  ${responsiveHeadingVariablesCss}
  ${media({
    lg: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    xs: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
  })}
`

const HeadingLarge = styled(BaseText.Heading)`
  ${responsiveHeadingVariablesCss}
  ${media({
    lg: css`
      font-size: var(--text-large);
      line-height: var(--text-lh-large);
    `,
    md: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    xs: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
  })}
`

const HeadingSmall = styled(BaseText.Heading)`
  ${responsiveHeadingVariablesCss}
  ${media({
    md: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
    xs: css`
      font-size: var(--text-tiny);
      line-height: var(--text-lh-tiny);
    `,
  })}
`

const Heading = (
  props:
    | HeadingParameters
    | {
        size: HeadingParameters["size"] | "mediumOnModal"
        children: React.ReactNode
      },
) => {
  switch (props.size) {
    case "large":
      return <HeadingLarge {...props} size={props.size} />
    case "medium":
      return <HeadingMedium {...props} size={props.size} />
    case "mediumOnModal":
      // @ts-expect-error TODO: fix this type
      return <HeadingMediumOnModal {...props} size={props.size} />
    case "small":
      return <HeadingSmall {...props} size={props.size} />
    default:
      return <BaseText.Heading {...props} size={props.size} />
  }
}

type DisplayParameters = Parameters<typeof BaseText.Display>[0]

const DisplayXLarge = styled(BaseText.Display)`
  ${displayVariablesCss}
  ${media({
    md: css`
      font-size: 64px;
      line-height: 72px;
    `,
    xs: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
  })}
`

const DisplayLarge = styled(BaseText.Display)`
  ${displayVariablesCss}
  ${media({
    xl: css`
      font-size: var(--text-large);
      line-height: var(--text-lh-large);
    `,
    md: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    xs: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
  })}
`

const DisplayMedium = styled(BaseText.Display)`
  ${displayVariablesCss}
  ${media({
    md: css`
      font-size: var(--text-medium);
      line-height: var(--text-lh-medium);
    `,
    xs: css`
      font-size: var(--text-small);
      line-height: var(--text-lh-small);
    `,
  })}
`

const Display = (props: DisplayParameters) => {
  if (props.size == "xlarge") {
    return <DisplayXLarge {...props} />
  } else if (props.size == "large") {
    return <DisplayLarge {...props} />
  } else if (props.size == "medium") {
    return <DisplayMedium {...props} />
  }
  return <BaseText.Display {...props} />
}

export const ResponsiveText = Object.assign(Body, {
  Body,
  Heading,
  Display,
})
