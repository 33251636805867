import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { useLazyLoadQuery } from "react-relay"
import {
  BlockchainActionList as UnstyledBlockchainActionList,
  blockchainActionListGenerator,
} from "@/components/blockchain/BlockchainActionList"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useToasts } from "@/hooks/useToasts"
import { DeployContractActionModalQuery } from "@/lib/graphql/__generated__/DeployContractActionModalQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import type { ConfigurationFormData } from "../ContractConfigurationForm"
import { ContractDeployTransactionModal } from "../ContractDeployTransactionModal"
import {
  DeployContractProgress,
  LOADING_CONTENT_STATE,
} from "../DeployContractProgress"

const NewBlockchainActionList = blockchainActionListGenerator(
  <DeployContractProgress state={LOADING_CONTENT_STATE.PENDING_TRANSACTION} />,
)

export type DeployContractActionModalProps = Omit<
  ConfigurationFormData,
  "chain"
> & {
  chain: ChainIdentifier
  onClose: () => unknown
}

const LazyDeployContractActionModal = ({
  name,
  symbol,
  chain,
  onClose,
  image,
  contract,
}: DeployContractActionModalProps) => {
  const { onReplace } = useMultiStepFlowContext()
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  const data = useLazyLoadQuery<DeployContractActionModalQuery>(
    graphql`
      query DeployContractActionModalQuery(
        $chain: ChainScalar!
        $tokenName: String!
        $tokenSymbol: String!
        $contractType: DropDeployContractType
      ) {
        chain(identifier: $chain) {
          ...ContractDeployTransactionModal_data
        }
        blockchain {
          deployContractAction(
            chain: $chain
            tokenName: $tokenName
            tokenSymbol: $tokenSymbol
            contractType: $contractType
          ) {
            ...BlockchainActionList_data
          }
        }
      }
    `,
    {
      chain,
      tokenName: name,
      tokenSymbol: symbol,
      contractType: {
        contractStandard: contract.standard,
        isProxy: contract.isProxy,
      },
    },
    { fetchPolicy: "network-only" },
  )

  return isMintYourOwnEnabled ? (
    <NewBlockchainActionList
      dataKey={[data.blockchain.deployContractAction]}
      onEnd={({ transaction } = {}) => {
        onReplace(
          <ContractDeployTransactionModal
            collectionLogo={image}
            dataKey={data.chain}
            isProxy={contract.isProxy}
            transaction={transaction}
            onClose={onClose}
          />,
        )
      }}
    />
  ) : (
    <UnstyledBlockchainActionList
      dataKey={[data.blockchain.deployContractAction]}
      onEnd={({ transaction } = {}) => {
        onReplace(
          <ContractDeployTransactionModal
            collectionLogo={image}
            dataKey={data.chain}
            isProxy={contract.isProxy}
            transaction={transaction}
            onClose={onClose}
          />,
        )
      }}
    />
  )
}

export const DeployContractActionModal = (
  props: DeployContractActionModalProps,
) => {
  const { showErrorMessage } = useToasts()
  const { onPrevious } = useMultiStepFlowContext()

  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()

  return (
    <ErrorBoundary
      onError={error => {
        showErrorMessage(error.message)
        onPrevious?.()
      }}
    >
      <Suspense
        fallback={
          isMintYourOwnEnabled ? (
            <DeployContractProgress
              state={LOADING_CONTENT_STATE.WAITING_SIGNATURE}
            />
          ) : (
            <BlockchainActionModalContent.Skeleton />
          )
        }
      >
        <LazyDeployContractActionModal {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}
