/**
 * @generated SignedSource<<17b6d4a94be41fa1591ebdae101b200c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingTransactionQuery$variables = {
  contractAddress?: string | null;
  hasContractAddress: boolean;
};
export type PendingTransactionQuery$data = {
  readonly collections?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly relayId: string;
        readonly slug: string;
        readonly " $fragmentSpreads": FragmentRefs<"CollectionCreatedModalContent_data">;
      } | null;
    } | null>;
  };
};
export type PendingTransactionQuery = {
  response: PendingTransactionQuery$data;
  variables: PendingTransactionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractAddress"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasContractAddress"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assetContractAddress",
    "variableName": "contractAddress"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingTransactionQuery",
    "selections": [
      {
        "condition": "hasContractAddress",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionTypeConnection",
            "kind": "LinkedField",
            "name": "collections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CollectionCreatedModalContent_data"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingTransactionQuery",
    "selections": [
      {
        "condition": "hasContractAddress",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionTypeConnection",
            "kind": "LinkedField",
            "name": "collections",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CollectionType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCategory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "233666fc12a4e2f33f1f3c23fba9a43b",
    "id": null,
    "metadata": {},
    "name": "PendingTransactionQuery",
    "operationKind": "query",
    "text": "query PendingTransactionQuery(\n  $contractAddress: AddressScalar\n  $hasContractAddress: Boolean!\n) {\n  collections(assetContractAddress: $contractAddress, first: 1) @include(if: $hasContractAddress) {\n    edges {\n      node {\n        relayId\n        slug\n        ...CollectionCreatedModalContent_data\n        id\n      }\n    }\n  }\n}\n\nfragment CollectionCreatedModalContent_data on CollectionType {\n  logo\n  ...collection_url\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "bb7948c23b32e2030824e6d14be48fea";

export default node;
