/**
 * @generated SignedSource<<3dc5e1480817a9a280c531adfc3a9be0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCollectionFormCollectionEditInput_data$data = {
  readonly displayData: {
    readonly cardDisplayStyle: CardDisplayStyle | null;
  };
  readonly relayId: string;
  readonly " $fragmentType": "useCollectionFormCollectionEditInput_data";
};
export type useCollectionFormCollectionEditInput_data$key = {
  readonly " $data"?: useCollectionFormCollectionEditInput_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCollectionFormCollectionEditInput_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCollectionFormCollectionEditInput_data"
};

(node as any).hash = "040ba7ea72132e99af1b1b9b89c7e112";

export default node;
