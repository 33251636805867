/**
 * @generated SignedSource<<35598540823be69c4bca945f060abd8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useCollectionFormUploadMutation$variables = {
  collection: string;
  hasBanner: boolean;
  hasFeaturedImage: boolean;
  hasLogo: boolean;
};
export type useCollectionFormUploadMutation$data = {
  readonly collections: {
    readonly uploadBanner?: {
      readonly fields: string;
      readonly method: string;
      readonly token: string;
      readonly url: string;
    };
    readonly uploadFeaturedImage?: {
      readonly fields: string;
      readonly method: string;
      readonly token: string;
      readonly url: string;
    };
    readonly uploadLogo?: {
      readonly fields: string;
      readonly method: string;
      readonly token: string;
      readonly url: string;
    };
  };
};
export type useCollectionFormUploadMutation = {
  response: useCollectionFormUploadMutation$data;
  variables: useCollectionFormUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collection"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBanner"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasFeaturedImage"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasLogo"
},
v4 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "method",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fields",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "token",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionMutationType",
    "kind": "LinkedField",
    "name": "collections",
    "plural": false,
    "selections": [
      {
        "condition": "hasBanner",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "UploadContextType",
            "kind": "LinkedField",
            "name": "uploadBanner",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasLogo",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "UploadContextType",
            "kind": "LinkedField",
            "name": "uploadLogo",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasFeaturedImage",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "UploadContextType",
            "kind": "LinkedField",
            "name": "uploadFeaturedImage",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionFormUploadMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCollectionFormUploadMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "e24c779948007147921ec355ce71f650",
    "id": null,
    "metadata": {},
    "name": "useCollectionFormUploadMutation",
    "operationKind": "mutation",
    "text": "mutation useCollectionFormUploadMutation(\n  $collection: CollectionRelayID!\n  $hasBanner: Boolean!\n  $hasLogo: Boolean!\n  $hasFeaturedImage: Boolean!\n) {\n  collections {\n    uploadBanner(collection: $collection) @include(if: $hasBanner) {\n      url\n      method\n      fields\n      token\n    }\n    uploadLogo(collection: $collection) @include(if: $hasLogo) {\n      url\n      method\n      fields\n      token\n    }\n    uploadFeaturedImage(collection: $collection) @include(if: $hasFeaturedImage) {\n      url\n      method\n      fields\n      token\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74e5eb2c4a4d124a1e17dbf8b38d8984";

export default node;
