import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

interface DeployContractStore {
  deployedContract?: {
    logoUrl: string
    blockExplorerName: string
    blockExplorerUrl: string
    slug: string
  }
  state: "initial" | "loading" | "success"
  contractDeployed: (
    deployedContract: DeployContractStore["deployedContract"],
  ) => void
  reset: () => void
}

const initialState = {
  deployedContract: undefined,
  state: "initial",
}

export const useDeployContractStore = create<DeployContractStore>()(
  immer(
    set =>
      ({
        ...initialState,
        reset: () => set(() => initialState),
        contractDeployed: deployedContract =>
          set((state: DeployContractStore) => {
            state.deployedContract = deployedContract
            state.state = "success"
          }),
      }) as DeployContractStore,
  ),
)
