import { useCallback, useEffect, useRef, useState } from "react"
import { isBefore, parseISO } from "date-fns"
import { useForm, UseFormReturn } from "react-hook-form"
import { readInlineData, useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { MediaInputValue } from "@/components/forms/MediaInput"
import { InputStatus } from "@/components/v2/inputs/Input.react"
import { TOAST_BOTTOM_OFFSET_DEFAULT } from "@/containers/ToastProvider.react"
import { useMultiAddressInput } from "@/hooks/useAddressInput"
import { ChainIdentifier } from "@/hooks/useChains/types"
import {
  useFlag,
  useRoyaltyRegistryEnforcedChains,
  useRoyaltyRegistryEnforcementDate,
} from "@/hooks/useFlag"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackCreateCollection,
  trackEditCollection,
} from "@/lib/analytics/events/collectionEvents"
import {
  trackCollectionManagerCreateCollectionPage,
  trackCollectionManagerEditCollectionPage,
} from "@/lib/analytics/events/pageEvents"
import { CollectionDetailsForm_data$data } from "@/lib/graphql/__generated__/CollectionDetailsForm_data.graphql"
import {
  CardDisplayStyle,
  RarityStrategyId,
  useCollectionForm_data$key,
} from "@/lib/graphql/__generated__/useCollectionForm_data.graphql"
import { useCollectionFormCollectionEditInput_data$key } from "@/lib/graphql/__generated__/useCollectionFormCollectionEditInput_data.graphql"
import {
  CollectionCreateMutationDataTypeInput,
  useCollectionFormCreateMutation,
} from "@/lib/graphql/__generated__/useCollectionFormCreateMutation.graphql"
import {
  CollectionModifyMutationDataTypeInput,
  useCollectionFormEditMutation,
} from "@/lib/graphql/__generated__/useCollectionFormEditMutation.graphql"
import { useCollectionFormUploadMutation } from "@/lib/graphql/__generated__/useCollectionFormUploadMutation.graphql"
import {
  getFirstNode,
  getNodes,
  graphql,
  toRelayId,
} from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { OrderedSet } from "@/lib/helpers/array"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { uploadFile } from "@/lib/helpers/file"
import { MediaType } from "@/lib/helpers/media"
import { basisPointsToPercentage, bn } from "@/lib/helpers/numberUtils"
import { WithInputValue } from "@/lib/helpers/validation"

const creatorFeeEarningsToBasisPoints = (
  payoutAddresses?: PayoutAddress[],
): CollectionModifyMutationDataTypeInput["creatorFees"] => {
  return payoutAddresses
    ?.filter(({ address, earningsPercentage }) => address && earningsPercentage)
    .map(({ address, earningsPercentage }) => ({
      address,
      basisPoints: bn(earningsPercentage).times(100).toNumber(),
    }))
}

const formDataToCommonCollectionInput = ({
  category,
  cardDisplayStyle,
  paymentAssetRelayIds,
  description,
  discordUrl,
  externalUrl,
  instagramUsername,
  mediumUsername,
  name,
  payoutAddresses,
  slug,
  telegramUrl,
  twitterUsername,
  isNsfw,
  raritiesNote,
  categoryV2,
  tags,
}: CollectionFormData) => {
  return {
    displayData: { cardDisplayStyle },
    name: name.trim(),
    slug,
    description,
    category: category?.slug ?? undefined,
    externalUrl: externalUrl.value,
    discordUrl: discordUrl?.value ?? undefined,
    twitterUsername,
    instagramUsername,
    mediumUsername,
    telegramUrl: telegramUrl.value,
    creatorFees: creatorFeeEarningsToBasisPoints(payoutAddresses),
    paymentAssets: paymentAssetRelayIds
      ? [...paymentAssetRelayIds.elements]
      : undefined,
    isNsfw,
    categoryV2: categoryV2 != null ? categoryV2 : undefined,
    tags,
    raritiesNote,
  }
}

const formDataToCollectionCreateInput = (
  formData: CollectionFormData,
): CollectionCreateMutationDataTypeInput => {
  return {
    ...formDataToCommonCollectionInput(formData),
    defaultChain: formData.defaultChain,
  }
}

const formDataToCollectionEditInput = (
  formData: CollectionFormData,
  collection: useCollectionFormCollectionEditInput_data$key,
): CollectionModifyMutationDataTypeInput => {
  const data = readInlineData(
    graphql`
      fragment useCollectionFormCollectionEditInput_data on CollectionType
      @inline {
        relayId
        displayData {
          cardDisplayStyle
        }
      }
    `,
    collection,
  )

  return {
    ...formDataToCommonCollectionInput(formData),
    collection: data.relayId,
    displayData: {
      ...data.displayData,
      cardDisplayStyle: formData.cardDisplayStyle,
    },
    traitOffersEnabled: formData.isTraitOffersEnabled,
    enabledRarities: formData.enabledRarities?.map(strategyId => ({
      strategyId,
    })),
  }
}

type CollectionFormProps = {
  dataKey: useCollectionForm_data$key
  refetch: () => unknown
  isRoyaltyRegistryEnforcedCollection?: boolean
  isEligibleForCreatorFeeEnforcement?: boolean
  onSubmit: (form: {
    formData: CollectionFormData
    didChangeCategory: boolean
    initialFormData: CollectionFormData
    onlyUpdatedPayouts: boolean
  }) => unknown
}

const DEFAULT_CARD_DISPLAY_STYLE: CardDisplayStyle = "CONTAIN"

type PayoutAddress = {
  address: string
  earningsPercentage: string
}

export type CollectionFormData = {
  cardDisplayStyle: CardDisplayStyle
  image?: MediaInputValue
  featuredImage?: MediaInputValue
  bannerImage?: MediaInputValue
  name: string
  slug: string
  description: string
  category: CollectionDetailsForm_data$data["categories"][number] | null
  categoryV2?: string
  discordUrl?: WithInputValue
  externalUrl: WithInputValue
  twitterUsername: string
  instagramUsername: string
  mediumUsername: string
  telegramUrl: WithInputValue
  payoutAddresses?: PayoutAddress[]
  defaultChain: ChainIdentifier
  paymentAssetRelayIds: OrderedSet<string, string> | undefined
  isNsfw: boolean
  isTraitOffersEnabled: boolean
  // Field is undefined when hidden by feature flag since not registered with useForm
  enabledRarities?: RarityStrategyId[]
  raritiesNote?: string
  tags: Array<string>
}

type CreatorFees = CollectionModifyMutationDataTypeInput["creatorFees"]

export interface UseCollectionForm {
  form: UseFormReturn<CollectionFormData>
  submit: () => void
  isEligibleForCreatorFeeEnforcement?: boolean
  isRoyaltyRegistryEnforcedCollection?: boolean
  addresses: (string | undefined)[]
  isResolvingEnsNames: boolean[]
  // This is just the status of name and slug, TODO this could probably be
  // handled by actual form errors instead of its own thing
  status: InputStatus
  setStatus: (status: InputStatus) => void
  socialsUpdated: boolean
  setSocialsUpdated: (socialsUpdated: boolean) => void
  openRoyaltyRegistryModal: () => void
  closeRoyaltyRegistryModal: () => void
  isRoyaltyRegistryModalOpen: boolean
  openSetTransferValidatorModal: () => void
  closeSetTransferValidatorModal: () => void
  isSetTransferValidatorModalOpen: boolean
  openSetRoyaltyInfoModal: () => void
  closeSetRoyaltyInfoModal: () => void
  isSetRoyaltyInfoModalOpen: boolean
  finishSettingRoyaltyInfo: () => void
  creatorFeesToRegister: CreatorFees | null
  setCreatorFeesToRegister: (creatorFees: CreatorFees | null) => void
  setDidChangeCategory: (didChangeCategory: boolean) => void
  getDefaultValues: () => CollectionFormData
  refetch: () => void
  finishSettingCreatorFees: () => void
  finishSettingTransferValidator: () => void
}

export const useCollectionForm = (
  dataKey: useCollectionForm_data$key | null,
  refetch: () => unknown,
): UseCollectionForm => {
  const router = useRouter()
  const data = useFragment<useCollectionForm_data$key>(
    graphql`
      fragment useCollectionForm_data on Query {
        paymentAssets(first: 750, validForCollection: $collection) {
          edges {
            node {
              asset {
                assetContract {
                  chain
                }
                imageUrl
              }
              isDefault
              relayId
            }
          }
        }
        collection(collection: $collection)
          @include(if: $isExistingCollection) {
          createdDate
          assetContracts(first: 2) {
            edges {
              node {
                name
                owner {
                  __typename
                }
              }
            }
          }
          category {
            slug
          }
          tags {
            slug
          }
          defaultChain {
            identifier
          }
          description
          discordUrl
          displayData {
            cardDisplayStyle
          }
          externalUrl
          featuredImageUrl
          instagramUsername
          imageUrl
          bannerImageUrl
          mediumUsername
          name
          isTraitOffersEnabled
          paymentAssets {
            relayId
          }
          relayId
          slug
          telegramUrl
          twitterUsername
          creatorFees {
            account {
              address
            }
            basisPoints
          }
          isNsfw
          parents(first: 30) {
            edges {
              node {
                imageUrl
                name
                slug
              }
            }
          }
          enabledRarities
          isCreatorFeesEnforced
          canSetRoyaltyInfo
          ...useCollectionFormCollectionEditInput_data
        }
      }
    `,
    dataKey,
  )
  const collection = data?.collection

  const [socialsUpdated, setSocialsUpdated] = useState(false)

  const { mutate } = useGraphQL()
  const royaltyRegistryEnforcementDate = useRoyaltyRegistryEnforcementDate()
  const [creatorFeesToRegister, setCreatorFeesToRegister] =
    useState<CreatorFees | null>(null)
  const assetContracts = getNodes(data?.collection?.assetContracts)
  const royaltyRegistryEnforcedChains = useRoyaltyRegistryEnforcedChains()

  const [status, setStatus] = useState<InputStatus>("standby")

  const {
    showErrorMessage,
    showInfoMessage,
    showSuccessMessage,
    setToastYOffset,
  } = useToasts()
  const [didChangeCategory, setDidChangeCategory] = useState(false)
  const t = useTranslate("collectionEdit")

  const isDiscordOauthEnabled = useFlag("is_discord_oauth_enabled")
  const isRoyaltyRegistryEnforcedCollection = data?.collection?.defaultChain
    .identifier
    ? assetContracts.length === 1 &&
      !!assetContracts[0].owner &&
      royaltyRegistryEnforcedChains.includes(
        data.collection.defaultChain.identifier,
      )
    : false
  const isEligibleForCreatorFeeEnforcement = Boolean(
    royaltyRegistryEnforcementDate &&
      data?.collection?.createdDate &&
      !isBefore(
        parseISO(data.collection.createdDate),
        royaltyRegistryEnforcementDate,
      ) &&
      assetContracts.length === 1,
  )
  const {
    isOpen: isRoyaltyRegistryModalOpen,
    open: openRoyaltyRegistryModal,
    close: closeRoyaltyRegistryModal,
  } = useIsOpen()

  const {
    isOpen: isSetTransferValidatorModalOpen,
    open: openSetTransferValidatorModal,
    close: closeSetTransferValidatorModal,
  } = useIsOpen()

  const {
    isOpen: isSetRoyaltyInfoModalOpen,
    open: openSetRoyaltyInfoModal,
    close: closeSetRoyaltyInfoModal,
  } = useIsOpen()

  const timeoutToCleanToastOffset = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (creatorFeesToRegister) {
      if (collection?.canSetRoyaltyInfo) {
        openSetRoyaltyInfoModal()
      } else {
        openRoyaltyRegistryModal()
      }
    }
  }, [
    creatorFeesToRegister,
    openRoyaltyRegistryModal,
    closeRoyaltyRegistryModal,
    collection?.canSetRoyaltyInfo,
    openSetRoyaltyInfoModal,
    closeSetRoyaltyInfoModal,
  ])

  useMountEffect(() => {
    collection
      ? trackCollectionManagerEditCollectionPage({
          collectionSlug: collection.slug,
        })
      : trackCollectionManagerCreateCollectionPage({})
  })

  const createCollection: CollectionFormProps["onSubmit"] = async ({
    formData,
    didChangeCategory,
  }) => {
    let collectionInput = formDataToCollectionCreateInput({
      ...formData,
      category: didChangeCategory ? formData.category : null,
    })

    collectionInput = await uploadCollectionImages(
      mutate,
      // for create flow, we don't have an existing relayId we can tie the upload to, so we send in a fake relay id
      toRelayId("CollectionType", -1),
      {
        ...collectionInput,
        logoImage: formData.image?.file,
        bannerImage: formData.bannerImage?.file,
        featuredImage: formData.featuredImage?.file,
      },
    )

    const {
      collections: { create },
    } = await mutate<useCollectionFormCreateMutation>(
      graphql`
        mutation useCollectionFormCreateMutation(
          $input: CollectionCreateMutationDataTypeInput!
        ) {
          collections {
            create(collectionInput: $input) {
              slug
              ...collection_url
            }
          }
        }
      `,
      { input: collectionInput },
      { shouldAuthenticate: true },
    )
    trackCreateCollection({ collectionSlug: create.slug, collectionInput })
    router.push(getCollectionUrl(create))
  }

  const editCollection: CollectionFormProps["onSubmit"] = async ({
    formData,
    didChangeCategory,
    initialFormData,
    onlyUpdatedPayouts,
  }) => {
    const collection = data?.collection
    if (!collection) {
      return
    }
    const { slug } = formData
    const { slug: currentSlug } = collection

    if (slug !== currentSlug) {
      if (
        !window.confirm(
          t(
            "edit.areYouSureConfirm",
            'Are you sure you want to change your URL? Links pointing to "{{collectionURL}}" will stop working',
            { collectionURL: `opensea.io/assets/${currentSlug}` },
            { forceString: true },
          ),
        )
      ) {
        return
      }
    }

    let collectionInput = formDataToCollectionEditInput(
      { ...formData, category: didChangeCategory ? formData.category : null },
      collection,
    )

    collectionInput = await uploadCollectionImages(mutate, collection.relayId, {
      ...collectionInput,
      logoImage: formData.image?.file,
      bannerImage: formData.bannerImage?.file,
      featuredImage: formData.featuredImage?.file,
    })

    // skip mutation if we should register the creator fees and only payout fields have changed
    if (
      !(
        (onlyUpdatedPayouts &&
          isEligibleForCreatorFeeEnforcement &&
          isRoyaltyRegistryEnforcedCollection) ||
        (onlyUpdatedPayouts && collection.canSetRoyaltyInfo)
      )
    ) {
      const response = await mutate<useCollectionFormEditMutation>(
        graphql`
          mutation useCollectionFormEditMutation(
            $input: CollectionModifyMutationInput!
          ) {
            collections {
              modify(input: $input) {
                relayId
                ...collection_url
              }
            }
          }
        `,
        {
          input: {
            collectionInput: {
              ...collectionInput,
              // If we're registering creator fees, we don't want to send the creatorFees to this mutation
              // they will be set by the watcher once submitted to royalty registry
              creatorFees:
                isEligibleForCreatorFeeEnforcement &&
                isRoyaltyRegistryEnforcedCollection
                  ? undefined
                  : collectionInput.creatorFees,
            },
          },
        },
        { shouldAuthenticate: true },
      )
      if (slug !== currentSlug) {
        router.push(getCollectionUrl(response.collections.modify))
      } else {
        refetch()
      }
    }

    trackEditCollection({
      collectionInput,
      intitialCollectionData: formDataToCollectionEditInput(
        initialFormData,
        collection,
      ),
    })

    if (
      collectionInput.creatorFees &&
      ((isEligibleForCreatorFeeEnforcement &&
        isRoyaltyRegistryEnforcedCollection) ||
        collection.canSetRoyaltyInfo)
    ) {
      setCreatorFeesToRegister(collectionInput.creatorFees)
    }
  }
  const getDefaultValues = useCallback(
    () => ({
      cardDisplayStyle:
        collection?.displayData.cardDisplayStyle ?? DEFAULT_CARD_DISPLAY_STYLE,
      image: collection?.imageUrl
        ? { url: collection.imageUrl, type: "image" as MediaType }
        : undefined,
      featuredImage: collection?.featuredImageUrl
        ? { url: collection.featuredImageUrl, type: "image" as MediaType }
        : undefined,
      bannerImage: collection?.bannerImageUrl
        ? { url: collection.bannerImageUrl, type: "image" as MediaType }
        : undefined,
      name: collection?.name ?? "",
      slug: collection?.slug ?? "",
      description: collection?.description ?? "",
      category: getFirstNode(collection?.parents) ?? null,
      externalUrl: {
        value: collection?.externalUrl ?? undefined,
        inputValue: collection?.externalUrl ?? "",
      },
      discordUrl: {
        value: collection?.discordUrl ?? undefined,
        inputValue: collection?.discordUrl ?? "",
      },
      twitterUsername: collection?.twitterUsername ?? "",
      instagramUsername: collection?.instagramUsername ?? "",
      mediumUsername: collection?.mediumUsername ?? "",
      telegramUrl: {
        value: collection?.telegramUrl ?? undefined,
        inputValue: collection?.telegramUrl ?? "",
      },
      payoutAddresses:
        collection?.creatorFees.map(({ account, basisPoints }) => ({
          address: account?.address || "",
          earningsPercentage: basisPointsToPercentage(basisPoints),
        })) ?? [],
      defaultChain: collection?.defaultChain.identifier ?? getEthereumChain(),
      paymentAssetRelayIds: new OrderedSet(
        p => p,
        collection?.paymentAssets.map(p => p.relayId) ??
          getNodes(data?.paymentAssets ?? { edges: [] })
            .filter(
              p =>
                p.isDefault &&
                p.asset.assetContract.chain ===
                  (collection?.defaultChain.identifier ?? getEthereumChain()),
            )
            .map(p => p.relayId),
      ),
      isNsfw: Boolean(collection?.isNsfw),
      isTraitOffersEnabled: Boolean(collection?.isTraitOffersEnabled),
      enabledRarities: [...(collection?.enabledRarities ?? [])],
      categoryV2: collection?.category?.slug ?? "",
      tags: collection?.tags?.map(t => t.slug) ?? [],
    }),
    [collection, data?.paymentAssets],
  )
  const form = useForm<CollectionFormData>({
    mode: "onChange",
    defaultValues: getDefaultValues(),
  })

  const reset = form.reset
  const finishSettingCreatorFees = useCallback(() => {
    setCreatorFeesToRegister(null)
    reset({}, { keepValues: true })
  }, [reset, setCreatorFeesToRegister])

  const finishSettingRoyaltyInfo = useCallback(async () => {
    await mutate<useCollectionFormEditMutation>(
      graphql`
        mutation useCollectionFormRoyaltyInfoMutation(
          $input: CollectionModifyMutationInput!
        ) {
          collections {
            modify(input: $input) {
              relayId
              ...collection_url
            }
          }
        }
      `,
      {
        input: {
          collectionInput: {
            collection: collection?.relayId ?? "",
            creatorFees: creatorFeesToRegister,
          },
        },
      },
      { shouldAuthenticate: true },
    )

    setCreatorFeesToRegister(null)
    reset({}, { keepValues: true })

    refetch()
  }, [collection?.relayId, creatorFeesToRegister, mutate, refetch, reset])

  const onSubmit = collection ? editCollection : createCollection
  const { watch, handleSubmit, formState } = form
  const payoutAddresses = watch("payoutAddresses") || []
  const { isResolvingEnsNames, addresses } = useMultiAddressInput(
    payoutAddresses.map(p => p.address),
  )
  useUpdateEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset])

  const submit = handleSubmit(async formData => {
    // TODO clean up after unleash flag is launched by removing the field for discordUrl completely
    if (isDiscordOauthEnabled) {
      delete formData["discordUrl"]
    }

    const formDataWithResolvedAddress = {
      ...formData,
      // We omit payment asset relay ids for the same reasoning as below
      ...(!formState.dirtyFields["paymentAssetRelayIds"] && {
        paymentAssetRelayIds: undefined,
      }),
      payoutAddresses: formState.dirtyFields["payoutAddresses"]
        ? payoutAddresses.map(({ earningsPercentage, address }, index) => {
            const resolvedAddress = addresses[index]

            if (!resolvedAddress) {
              throw new Error(
                t(
                  "forms.collectionForm.resolvedAddressError",
                  "No resolved address found for payout address {{address}} at collection form submission",
                  { address },
                  { forceString: true },
                ),
              )
            }

            return {
              earningsPercentage,
              address: resolvedAddress,
            }
          })
        : // We omit payout addresses if we haven't touched it. Not doing this leads to issues that already have > 10% fees set.
          // as they won't be able to make any further edits to this collection. Perhaps we should move this to BE logic?

          undefined,
    } as CollectionFormData

    const didEnableRarities =
      !collection?.enabledRarities.includes("OPENRARITY") &&
      formDataWithResolvedAddress.enabledRarities?.includes("OPENRARITY")

    try {
      const onlyUpdatedPayouts = Boolean(
        Object.keys(formState.dirtyFields).length === 1 &&
          formState.dirtyFields["payoutAddresses"],
      )
      await onSubmit({
        formData: formDataWithResolvedAddress,
        didChangeCategory,
        initialFormData: getDefaultValues(),
        onlyUpdatedPayouts,
      })
      setStatus("valid")
      if (didEnableRarities) {
        showInfoMessage(
          t(
            "forms.collectionForm.rarities.processing",
            "OpenRarity rankings are processing for your collection and will start appearing soon.",
          ),
        )
      }
      if (
        !(
          onlyUpdatedPayouts &&
          ((isEligibleForCreatorFeeEnforcement &&
            isRoyaltyRegistryEnforcedCollection) ||
            collection?.canSetRoyaltyInfo)
        )
      ) {
        showSuccessMessage(
          collection
            ? t("forms.collectionForm.updated", "Updated!")
            : t("forms.collectionForm.created", "Created"),
        )
      }
      setSocialsUpdated(false)
    } catch (error) {
      showErrorMessage(
        t(
          "forms.collectionForm.submitError",
          "Error submitting request: {{message}}",
          { message: error.message },
        ),
      )
      setStatus("invalid")
    } finally {
      timeoutToCleanToastOffset.current = setTimeout(() => {
        setToastYOffset(TOAST_BOTTOM_OFFSET_DEFAULT)
      }, 12000)
    }
  })
  const finishSettingTransferValidator = useCallback(async () => {
    // First let's save the fees form in case they were modified but not saved,
    // so the changes aren't lost.
    if (formState.isDirty) {
      await submit()
    }

    // It takes a couple of seconds for the backend to update via the watcher.
    let retryCount = 0
    const interval = setInterval(() => {
      refetch()
      retryCount++
      if (retryCount > 20 || data?.collection?.isCreatorFeesEnforced) {
        clearInterval(interval)
      }
    }, 1000)
  }, [refetch, data, formState, submit])

  return {
    form,
    isEligibleForCreatorFeeEnforcement,
    isRoyaltyRegistryEnforcedCollection,
    submit,
    addresses,
    isResolvingEnsNames,
    status,
    setStatus,
    socialsUpdated,
    setSocialsUpdated,
    isRoyaltyRegistryModalOpen,
    openRoyaltyRegistryModal,
    closeRoyaltyRegistryModal,
    isSetTransferValidatorModalOpen,
    openSetTransferValidatorModal,
    closeSetTransferValidatorModal,
    isSetRoyaltyInfoModalOpen,
    openSetRoyaltyInfoModal,
    closeSetRoyaltyInfoModal,
    finishSettingRoyaltyInfo,
    creatorFeesToRegister,
    setCreatorFeesToRegister,
    setDidChangeCategory,
    getDefaultValues,
    refetch,
    finishSettingCreatorFees,
    finishSettingTransferValidator,
  }
}

const UploadMutation = graphql`
  mutation useCollectionFormUploadMutation(
    $collection: CollectionRelayID!
    $hasBanner: Boolean!
    $hasLogo: Boolean!
    $hasFeaturedImage: Boolean!
  ) {
    collections {
      uploadBanner(collection: $collection) @include(if: $hasBanner) {
        url
        method
        fields
        token
      }
      uploadLogo(collection: $collection) @include(if: $hasLogo) {
        url
        method
        fields
        token
      }
      uploadFeaturedImage(collection: $collection)
        @include(if: $hasFeaturedImage) {
        url
        method
        fields
        token
      }
    }
  }
`

export const uploadCollectionImages = async <
  I extends (
    | CollectionCreateMutationDataTypeInput
    | CollectionModifyMutationDataTypeInput
  ) & {
    bannerImage: File | null | undefined
    logoImage: File | null | undefined
    featuredImage: File | null | undefined
  },
>(
  mutate: ReturnType<typeof useGraphQL>["mutate"],
  collection: string, // relayId
  input: I,
  shouldAuthenticate = true,
) => {
  const { bannerImage, logoImage, featuredImage, ...rest } = input

  if (bannerImage || logoImage || featuredImage) {
    const { collections } = await mutate<useCollectionFormUploadMutation>(
      UploadMutation,
      {
        collection,
        hasBanner: Boolean(bannerImage),
        hasLogo: Boolean(logoImage),
        hasFeaturedImage: Boolean(featuredImage),
      },
      { shouldAuthenticate },
    )

    const uploads: Promise<Response>[] = []

    if (collections.uploadBanner && bannerImage) {
      uploads.push(uploadFile(collections.uploadBanner, bannerImage))
      rest["bannerImageToken"] = collections.uploadBanner.token
    }
    if (collections.uploadLogo && logoImage) {
      uploads.push(uploadFile(collections.uploadLogo, logoImage))
      rest["logoImageToken"] = collections.uploadLogo.token
    }
    if (collections.uploadFeaturedImage && featuredImage) {
      uploads.push(uploadFile(collections.uploadFeaturedImage, featuredImage))
      rest["featuredImageToken"] = collections.uploadFeaturedImage.token
    }

    await Promise.all(uploads)
  }

  return rest
}
