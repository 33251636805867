/**
 * @generated SignedSource<<8da3f4d670f8f84c50aabbd12919308c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
export type RarityStrategyId = "OPENRARITY" | "%future added value";
export type SafelistRequestStatus = "APPROVED" | "DISABLED_TOP_TRENDING" | "NOT_REQUESTED" | "REQUESTED" | "VERIFIED" | "%future added value";
export type CollectionModifyMutationInput = {
  clientMutationId?: string | null;
  collectionInput: CollectionModifyMutationDataTypeInput;
};
export type CollectionModifyMutationDataTypeInput = {
  authorizedEditors?: ReadonlyArray<string> | null;
  bannerImageToken?: string | null;
  category?: string | null;
  categoryV2?: string | null;
  collection: string;
  creatorFees?: ReadonlyArray<FeeDataInput> | null;
  description?: string | null;
  discordUrl?: string | null;
  displayData?: DisplayDataTypeInput | null;
  enabledRarities?: ReadonlyArray<RarityEnabledDataInputType> | null;
  externalUrl?: string | null;
  featuredImageToken?: string | null;
  instagramUsername?: string | null;
  isNsfw?: boolean | null;
  logoImageToken?: string | null;
  mediumUsername?: string | null;
  name?: string | null;
  paymentAssets?: ReadonlyArray<string> | null;
  raritiesNote?: string | null;
  safelistRequestStatus?: SafelistRequestStatus | null;
  slug?: string | null;
  tags?: ReadonlyArray<string> | null;
  telegramUrl?: string | null;
  traitOffersEnabled?: boolean | null;
  twitterUsername?: string | null;
};
export type FeeDataInput = {
  address: string;
  basisPoints: number;
};
export type DisplayDataTypeInput = {
  cardDisplayStyle?: CardDisplayStyle | null;
  images?: ReadonlyArray<string> | null;
};
export type RarityEnabledDataInputType = {
  strategyId?: RarityStrategyId | null;
};
export type useCollectionFormRoyaltyInfoMutation$variables = {
  input: CollectionModifyMutationInput;
};
export type useCollectionFormRoyaltyInfoMutation$data = {
  readonly collections: {
    readonly modify: {
      readonly relayId: string;
      readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
    };
  };
};
export type useCollectionFormRoyaltyInfoMutation = {
  response: useCollectionFormRoyaltyInfoMutation$data;
  variables: useCollectionFormRoyaltyInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCategory",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCollectionFormRoyaltyInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineDataFragmentSpread",
                "name": "collection_url",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCollectionFormRoyaltyInfoMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8f155b9b2ded5799b62cbb5857b1620",
    "id": null,
    "metadata": {},
    "name": "useCollectionFormRoyaltyInfoMutation",
    "operationKind": "mutation",
    "text": "mutation useCollectionFormRoyaltyInfoMutation(\n  $input: CollectionModifyMutationInput!\n) {\n  collections {\n    modify(input: $input) {\n      relayId\n      ...collection_url\n      id\n    }\n  }\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "b66ab85956df99a5dfdfccd8c785638b";

export default node;
