import React from "react"
import { Text } from "@opensea/ui-kit"
import { Transaction } from "@/components/blockchain/BlockchainActionList/useHandleBlockchainActions"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackContractDeployFailed } from "@/lib/analytics/events/primaryDropsSelfServe"

export const FailedTransaction = ({
  transaction,
  blockExplorerName,
}: {
  blockExplorerName: string
  transaction?: Transaction
}) => {
  const t = useTranslate("collectionEdit")

  useMountEffect(trackContractDeployFailed)

  return (
    <>
      <Modal.Body.Title>
        {t("contractDeploy.failed.title", "Something went wrong")}
      </Modal.Body.Title>
      <Block marginY="24px">
        <Text.Body asChild className="text-center" size="medium">
          <p>
            {t(
              "contractDeploy.failed.description",
              "Your contract failed to deploy. Please try again later.",
            )}
          </p>
        </Text.Body>
      </Block>
      {transaction?.blockExplorerLink && (
        <Block marginBottom="48px">
          <Link href={transaction.blockExplorerLink}>
            {t(
              "contractDeploy.pending.explorerLink",
              "View on {{explorerName}}",
              { explorerName: blockExplorerName },
            )}
          </Link>
        </Block>
      )}
    </>
  )
}
