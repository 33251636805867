import { useCallback } from "react"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { EventProperties } from "@/lib/analytics/types"

export const useStudioTracking = (event_name: string) => {
  const track = useTrackingFn<EventProperties>(`studio - ${event_name}`)

  return useCallback(
    (properties: EventProperties = {}) => track(properties),
    [track],
  )
}
